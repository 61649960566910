* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}

.loading-screen {
    height: 100vh;
    background-color: black;
}

.spinner {
    position: relative;
    width: fit-content;
    height: 100%;
    margin: auto;
}

.spinner div {
    position: absolute;
    width: 200px;
    height: 200px;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
    transform: translate(-50%, -50%);
    border: 12px solid #fff;
    border-radius: 50%;
    animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.spinner div:nth-child(1) {
    animation-delay: -0.45s;
}

.spinner div:nth-child(2) {
    animation-delay: -0.3s;
}

.spinner div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}